// AI-GEN Start - Cursor
import { useContext } from 'react';
import { ActionProvider } from "./ActionProvider";
import { useChat, ChatContext } from '../context/chatContext';

// AI-GEN Start - Cursor
export const DEFAULT_QUESTION = 'I require assistance with the provided SIP Trace.';
export const SESSION_JWT_TOKEN_KEY = 'sip_trace_copilot_jwt_token';
export const ERROR_UNAUTHORIZED = 'Unauthorized access or token has expired!';

export class MessageParser {
  actionProvider: ActionProvider;
  chatContext: ReturnType<typeof useChat>;

  constructor(actionProvider: ActionProvider) {
    this.actionProvider = actionProvider;
    this.chatContext = useContext(ChatContext);
  }

  async parse(input: string): Promise<void> {
    let message = input.trim();
    if (message === '') {
      // In case of empty user input, we send the default question
      message = DEFAULT_QUESTION;
      this.actionProvider.amendLatestMessage(message);
    }

    if (this.chatContext.sipTraceContent.trim() === '') {
      this.actionProvider.appendNewMessage("Empty SIP trace provided, abort.");
      this.chatContext.setBlockingStatus(false);
      return;
    } else {
      message = `${message}\nSIP Trace:\n${this.chatContext.sipTraceContent.trim()}`;
    }

    this.actionProvider.appendNewMessage("thinking"); // AI-GEN - Cursor

    const token = sessionStorage.getItem(SESSION_JWT_TOKEN_KEY);
    if (!token || token.trim() === '') {
      this.actionProvider.amendLatestMessage(ERROR_UNAUTHORIZED);
      this.chatContext.setBlockingStatus(false);
      return;
    }

    let threadIdFromParser = null;
    if (!this.actionProvider.chatContext.threadId) {
      threadIdFromParser = await this.actionProvider.createThread("user123");
    }

    if (!this.actionProvider.chatContext.isSubscribed) {
      await this.actionProvider.subscribeToMessages(threadIdFromParser);
    }

    this.actionProvider.sendMessage(message, threadIdFromParser)
  }
}
// AI-GEN END
// AI-GEN End