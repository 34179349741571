// AI-GEN Start - ChatGPT
import { createContext, useContext, useState, ReactNode } from 'react';

interface ChatState {
  threadId: string | null;
  isSubscribed: boolean;
  sipTraceContent: string; // AI-GEN - Cursor
  isBlocking: boolean; // AI-GEN - Cursor
  setThreadId: (threadId: string | null) => void;
  setSubscriptionStatus: (isSubscribed: boolean) => void;
  setSipTraceContent: (sipTraceContent: string) => void; // AI-GEN - Cursor
  setBlockingStatus: (waiting: boolean) => void; // AI-GEN - Cursor
}

const initialState: ChatState = {
  threadId: null,
  isSubscribed: false,
  // AI-GEN START - Cursor
  sipTraceContent: '',
  setThreadId: () => { },
  setSubscriptionStatus: () => { },
  setSipTraceContent: () => { },
  isBlocking: false,
  setBlockingStatus: () => { },
  // AI-GEN END
};

export const ChatContext = createContext<ChatState>(initialState);

// Ensure you export ChatContext if you're using it outside this file
export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [threadId, setThreadId] = useState<string | null>(initialState.threadId);
  const [isSubscribed, setSubscriptionStatus] = useState<boolean>(initialState.isSubscribed);
  // AI-GEN START - Cursor
  const [sipTraceContent, setSipTraceContent] = useState<string>(initialState.sipTraceContent);
  const [isBlocking, setBlockingStatus] = useState<boolean>(initialState.isBlocking);
  // AI-GEN END

  return (
    <ChatContext.Provider value={{ threadId, isSubscribed, sipTraceContent, isBlocking, setThreadId, setSubscriptionStatus, setSipTraceContent, setBlockingStatus}}>
      {children}
    </ChatContext.Provider>
  );
};

// AI-GEN End