// AI-GEN Start - Cursor
import { Amplify } from 'aws-amplify';
import * as API from '@aws-amplify/api';

// Initialize Amplify
if (process.env.REACT_APP_API_ENDPOINT === undefined) { // AI-GEN - Claude
  throw Error('GraphQL API Endpoint undefined')
}

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: process.env.REACT_APP_API_ENDPOINT, // AI-GEN - Claude
      region: process.env.REACT_APP_REGION, // AI-GEN - Claude
      defaultAuthMode: 'none',
    }
  },
});

export const AppSyncClient = API;

// AI-Gen End