// AI-GEN START - Cursor
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChatProvider } from './context/chatContext';

const root = ReactDOM.createRoot(
  document.getElementById('react-app-container') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChatProvider>
      <App />
    </ChatProvider>
  </React.StrictMode>
);
// AI-GEN END