// AI-GEN START - Cursor
import React from 'react';
// AI-GEN START - Cursor
import markdownit from 'markdown-it';

// Adjusted regular expression to identify any content in between 【】
const urlPattern = /【(.*?)】/g;

const md = markdownit();

function formatMessage(rawText: string): string {
  let text = rawText.replace(urlPattern, '');
  if (/^```markdown/.test(text)) {
    // The response may start with ""```markdown", then try to remove the start/end tag
    text = text.replace(/^```markdown/, '');
    text = text.replace(/```$/, '');
  }
  return md.render(text);
}

const CustomMessageBox = (props: any) : React.ReactElement => {
  const chatBoxCustomStyles = { backgroundColor: '' };

  // Get the last message
  const messageId = props.payload; // AI-GEN - Cursor
  const messageObject = props.state.messages.find((message: any) => message.id === messageId); // AI-GEN - Cursor
  const message: string = messageObject ? messageObject.message : ''; // AI-GEN - Cursor
  const isThinking = message === 'thinking'; // AI-GEN - Cursor
  return isThinking ? (<div/>): (
    // AI-GEN START - Cursor
    <div className="react-chatbot-kit-chat-bot-message-container">
      <div className="react-chatbot-kit-chat-bot-avatar">
        <div className="react-chatbot-kit-chat-bot-avatar-container">
          <p className="react-chatbot-kit-chat-bot-avatar-letter">B</p>
        </div>
      </div>
      <div
        className="react-chatbot-kit-chat-bot-message"
        style={chatBoxCustomStyles}
        dangerouslySetInnerHTML={{ __html: formatMessage(message) }}
      />
    </div>
    // AI-GEN END
  );
};

export default CustomMessageBox;
// AI-GEN END