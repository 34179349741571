// AI-GEN Start - ChatGPT
import { gql } from 'graphql-tag';

export type CreateThreadInput = {
  userId: string;
}

export type CreateThreadOutput = {
  createThread: {
    threadId: string;
    userId: string;
    createdAt: string;
  }
};

export const CREATE_THREAD_MUTATION = gql`
  mutation CreateThread($input: CreateThreadInput!) {
    createThread(input: $input) {
      threadId
      userId
      createdAt
    }
  }
`;

export type SendMessageInput = {
  threadId: string;
  content: string;
  token: string;
};

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      threadId
      createdAt
    }
  }
`;

export type PublishResultOutput = {
  threadId: string;
  runId: string;
  eventType: EventType;
  textDelta: string;
  createdAt: string;
};

enum EventType {
  messageCreated = 'messageCreated',
  messageDelta = 'messageDelta',
  messageDone = 'messageDone', // AI-GEN - Cursor
  runDone = 'runDone' // AI-GEN - Cursor
}

export const PUBLISH_EVENT_SUBSCRIPTION = gql`
  subscription EventPublished($threadId: String!) {
    eventPublished(threadId: $threadId) {
      threadId
      runId
      eventType
      textDelta
      createdAt
    }
  }
`;

export type MessageResponseSubscription = {
  eventPublished: PublishResultOutput;
};
// AI-GEN End