// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* AI-GEN START - ChatGPT */
.thinking {
    position: relative;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 20px;
    font-style: italic;
    color: #555;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: inline-block;
  }
  
  .thinking::before,
  .thinking::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 0px;
    height: 0px;
    border-style: solid;
  }
  
  .thinking::before {
    left: 50%;
    border-width: 8px 8px 0 8px;
    border-color: #f0f0f0 transparent transparent transparent;
  }
  
  .thinking::after {
    left: calc(50% - 8px);
    border-width: 8px 8px 0 8px;
    border-color: #fff transparent transparent transparent;
    margin-top: -1px; /* Adjust this value if there is a gap */
  }
  
  @keyframes thinkingAnimation {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  .thinking {
    animation: thinkingAnimation 1.5s ease-in-out infinite;
  }
  /* AI-GEN END */`, "",{"version":3,"sources":["webpack://./src/chatbot/customMessages/stylesheet/Thinking.css"],"names":[],"mappings":";AACA,2BAA2B;AAC3B;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,wCAAwC;IACxC,qBAAqB;EACvB;;EAEA;;IAEE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,SAAS;IACT,2BAA2B;IAC3B,yDAAyD;EAC3D;;EAEA;IACE,qBAAqB;IACrB,2BAA2B;IAC3B,sDAAsD;IACtD,gBAAgB,EAAE,wCAAwC;EAC5D;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,YAAY;IACd;EACF;;EAEA;IACE,sDAAsD;EACxD;EACA,eAAe","sourcesContent":["\n/* AI-GEN START - ChatGPT */\n.thinking {\n    position: relative;\n    padding: 10px;\n    background-color: #f0f0f0;\n    border-radius: 20px;\n    font-style: italic;\n    color: #555;\n    margin: 20px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    display: inline-block;\n  }\n  \n  .thinking::before,\n  .thinking::after {\n    content: '';\n    position: absolute;\n    top: 100%;\n    width: 0px;\n    height: 0px;\n    border-style: solid;\n  }\n  \n  .thinking::before {\n    left: 50%;\n    border-width: 8px 8px 0 8px;\n    border-color: #f0f0f0 transparent transparent transparent;\n  }\n  \n  .thinking::after {\n    left: calc(50% - 8px);\n    border-width: 8px 8px 0 8px;\n    border-color: #fff transparent transparent transparent;\n    margin-top: -1px; /* Adjust this value if there is a gap */\n  }\n  \n  @keyframes thinkingAnimation {\n    0%, 100% {\n      opacity: 1;\n    }\n    50% {\n      opacity: 0.5;\n    }\n  }\n  \n  .thinking {\n    animation: thinkingAnimation 1.5s ease-in-out infinite;\n  }\n  /* AI-GEN END */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
