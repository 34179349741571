// AI-GEN Start - ChatGPT
import Chatbot from 'react-chatbot-kit'
import config from './config/chatbotConfig'
import { MessageParser, DEFAULT_QUESTION } from "./chatbot/MessageParser"
import { ActionProvider } from "./chatbot/ActionProvider"
import { useChat } from './context/chatContext';
import './main.css';;

// AI-GEN Start - Cursor
function App() {
  const { sipTraceContent, setSipTraceContent, isBlocking, setBlockingStatus } = useChat();

  function chatBotValidate() {
    // The bot do not allow send new message before previous message response back or failure
    if (isBlocking) {
      console.warn('The ChatBot UI is blocking!!!');
      return false;
    } else {
      setBlockingStatus(true);
      return true;
    }
  }

  return (
    <div className="App">
      <textarea
        id='sip-trace-file-content'
        className="sip-trace-file-content"
        placeholder='Paste SIP trace file content here'
        value={sipTraceContent}
        onChange={e => setSipTraceContent(e.target.value)}
      ></textarea>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        validator={chatBotValidate}
        headerText="Paste the SIP trace file above and let's start the conversation."
        placeholderText={DEFAULT_QUESTION}
      />
    </div>
  );
}
// AI-GEN END
export default App;

// AI-GEN End