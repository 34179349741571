// AI-GEN START - Cursor
import React from 'react';
import './stylesheet/Thinking.css';

export const Thinking = () : React.ReactElement => {
  return (
    <div className="thinking">Kandy UCaaS SIP Trace CoPilot is thinking...</div>
  );
};

export default Thinking;
// AI-GEN END