// AI-GEN Start - ChatGPT
import React from "react"; // AI-GEN Cursor
import Thinking from "../chatbot/customMessages/Thinking"; // AI-GEN - Cursor
import CustomMessageBox from "../chatbot/customMessages/CustomMessageBox"; // AI-GEN - Cursor
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig"; // AI-GEN - Cursor


const config: IConfig = {
  initialMessages: [
    { type: "text", id: 1, message: "Hi! How can I help you today?" },
  ],
  botName: "Kandy UCaaS SIP Trace CoPilot",
  customMessages: {
    custom: (props: any) : React.ReactElement => <CustomMessageBox {...props} />, // AI-GEN Cursor
  },
  // AI-GEN START - Cursor
  widgets: [
    {
      widgetName: 'thinking',
      widgetFunc: (props: any) : React.ReactElement => <Thinking/>,
      props: [],
      mapStateToProps: []
    },
  ]
  // AI-GEN END
};
  
export default config;
// AI-GEN End