/* eslint-disable @typescript-eslint/no-explicit-any */
// AI-GEN Start - Cursor
import { useContext } from 'react';
import { graphqlOperation, GraphqlSubscriptionResult } from '@aws-amplify/api-graphql';
import { GraphQLResult } from '@aws-amplify/api';
import { Subscription } from 'rxjs';
import { createCustomMessage } from 'react-chatbot-kit';

// Import GraphQL operations and client
import { 
  CREATE_THREAD_MUTATION, 
  CreateThreadInput, 
  CreateThreadOutput, 
  PUBLISH_EVENT_SUBSCRIPTION, 
  MessageResponseSubscription, 
  SEND_MESSAGE_MUTATION, 
  SendMessageInput, 
  PublishResultOutput 
} from './graphqlOperations';
import { AppSyncClient } from './appsyncClient';


// Import ChatContext
import { useChat, ChatContext } from '../context/chatContext';
import { ERROR_UNAUTHORIZED, SESSION_JWT_TOKEN_KEY } from './MessageParser';

interface ChatBotMessage {
  text: string;
  id: number;
  delay?: number; // AI-GEN - Cursor
}

export const InitialiseChatContext = () => {
  return useContext(ChatContext);
}

export class ActionProvider {
  createChatBotMessage: (text: string, options?: any) => ChatBotMessage;
  setState: (updateState: (prevState: any) => any) => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  client: ReturnType<typeof AppSyncClient.generateClient>;
  chatContext: ReturnType<typeof useChat>;
  messageSubscription: Subscription | null;
  messageDeltas: { createdAt: string, text: string }[] = []; // AI-GEN - Cursor

  constructor(createChatBotMessage: (text: string, options?: any) => ChatBotMessage, setStateFunc: (updateState: (prevState: any) => any) => void) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.client = AppSyncClient.generateClient();
    this.chatContext = InitialiseChatContext();
    this.messageSubscription = null;
  }

  async createThread(userId: string): Promise<string> {
    try {
      const threadMutationResult = await this.client.graphql<CreateThreadInput>(
        graphqlOperation(CREATE_THREAD_MUTATION, { 
          input: { userId }
        }, sessionStorage.getItem(SESSION_JWT_TOKEN_KEY))
      ) as GraphQLResult<CreateThreadOutput>;
      const threadId = threadMutationResult.data.createThread.threadId;
      this.chatContext.setThreadId(threadId); // Use context to set threadId
      return threadId;
    } catch (error: any) {
      console.log(JSON.stringify(error));
      if (error.errors && error.errors[0].message === 'Unauthorized') {
        this.amendLatestMessage(ERROR_UNAUTHORIZED);
      } else {
        this.amendLatestMessage("There is an error when initializing the session.");
      }
      return '';
    }
  }

  async sendMessage(content: string, threadIdFromParser: string | null = null) {
    const currentThreadId = this.chatContext.threadId || threadIdFromParser;
    if (!currentThreadId) {
      console.error("Thread ID is not set. Cannot send message.");
      return;
    }

    try {
      await this.client.graphql<SendMessageInput>(
        graphqlOperation(SEND_MESSAGE_MUTATION, {
          input: {
            threadId: currentThreadId,
            content,
            token: sessionStorage.getItem(SESSION_JWT_TOKEN_KEY),
          }
        }, sessionStorage.getItem(SESSION_JWT_TOKEN_KEY))
      );
    } catch (error: any) {
      console.log(JSON.stringify(error));
      this.amendLatestMessage(ERROR_UNAUTHORIZED);
      this.chatContext.setBlockingStatus(false); // AI-GEN - Cursor
    }
  }

  async subscribeToMessages(threadIdFromParser: string | null = null) {
    const currentThreadId = this.chatContext.threadId || threadIdFromParser;
    this.chatContext.setSubscriptionStatus(true);
    const subscription = await this.client.graphql<PublishResultOutput>(
      graphqlOperation(PUBLISH_EVENT_SUBSCRIPTION, {
        threadId: currentThreadId
      }, sessionStorage.getItem(SESSION_JWT_TOKEN_KEY))
    ) as GraphqlSubscriptionResult<MessageResponseSubscription>;

    this.messageSubscription = subscription.subscribe({
      next: ({ data }) => {
        // AI-GEN START - Cursor
        if (data.eventPublished.eventType === 'messageCreated') {
          // noop
        } else if (data.eventPublished.eventType === 'messageDelta') {
          // Insert the textDelta in the sorted array
          this.messageDeltas.push({ createdAt: data.eventPublished.createdAt, text: data.eventPublished.textDelta }); // AI-GEN - Cursor
          this.messageDeltas.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()); // AI-GEN - Cursor
          // AI-GEN START - Cursor
          const messagePartial = this.messageDeltas.map(delta => delta.text).join('');
          this.amendLatestMessage(messagePartial);
          // AI-GEN END
        } else if (data.eventPublished.eventType === 'runDone') {
          this.messageDeltas = [];
          this.chatContext.setBlockingStatus(false); // AI-GEN - Cursor
        }
        // AI-GEN END
        // AI-GEN END
      },
      error: error => { 
        console.error(error)
        this.unsubscribeFromMessages();
        this.chatContext.setBlockingStatus(false); // AI-GEN - Cursor
      }
    });
  }

  unsubscribeFromMessages() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
      this.messageSubscription = null; // Reset the subscription property
      this.chatContext.setSubscriptionStatus(false); // Use context to set subscription status
    }
  }

  // AI-GEN START - Cursor
  appendNewMessage(text: string): void {
    const options = text !== 'thinking' ? {} : { widget: 'thinking' }; // AI-GEN - Cursor
    const message = createCustomMessage(text, 'custom', options); // AI-GEN - Cursor
    message.payload = message.id; // AI-GEN - Cursor
    this.setState((prevState: any) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  }

  amendLatestMessage(text: string): void {
    this.setState((prevState: any) => {
      const latestMessage = prevState.messages[prevState.messages.length - 1];
      latestMessage.message = text;

      // Remove the thinking widget
      latestMessage.widget = undefined; // AI-GEN - Cursor 

      return {
        ...prevState,
        messages: prevState.messages,
      };
    });
  }
  // AI-GEN END
}
// AI-GEN End